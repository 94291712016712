import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const PrivacyPolicy = () => (
  <Layout>
    <Helmet title={'About Payment Services Directive - PSD2'} />
    <Header title="Payment Services Directive">PSD2.ie privacy policy</Header>
    <Container center={false}>
      <h2>Privacy Policy</h2>
      <p>Last updated: 2019-11-09</p>
      <p>
        We operates https://www.psd2.ie (the "Site"). This page informs you of
        our policies regarding the collection, use and disclosure of Personal
        Information we receive from users of the Site
      </p>
      <p>
        We use your Personal Information only for providing and improving the
        Site. By using the Site, you agree to the collection and use of
        information in accordance with this policy
      </p>

      <h2>Information Collection And Use</h2>
      <p>
        At this time we do not collect personal information such as your email,
        name, address, etc.
      </p>
      <p>
        We do collect some browsing information from our web logs and through
        the use of google analytics. We do not use this data for marketing
        purposes, nor do we share this data with third-parties. The exception is
        Google Analytics data which is already hosted with google, but not used
        for any further purpose other than showing site usage analytics.
      </p>
      <p>The details of the use of google analytics are details below.</p>

      <h2>Log data</h2>
      <p>
        Like many site operators, we collect information that your browser sends
        whenever you visit our Site ("Log Data"). This Log Data may include
        information such as your computer's Internet Protocol ("IP") address,
        browser type, browser version, the pages of our Site that you visit, the
        time and date of your visit, the time spent on those pages and other
        statistics.
      </p>

      <h2>Google Analytics</h2>
      <p>
        We have integrated the component Google Analytics (with anonymisation
        function) on this website.
      </p>

      <p>
        Google Analytics is a web analytics service. Web analysis is the
        gathering, collection and analysis of data about the behavior of
        visitors to websites. Among other things, a web analysis service
        collects data on which website a data subject has come to a website from
        (so-called referrers), which subpages of the website were accessed or
        how often and for which period of time a subpage was viewed. A web
        analysis is mainly used to optimize a website and for the cost-benefit
        analysis of Internet advertising.
      </p>

      <p>
        The operator of the Google Analytics component is Google Inc., 1600
        Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
      </p>

      <p>
        Google Analytics uses cookies. The information generated by the cookie
        about your use of this website is usually transmitted to a Google server
        in the USA and stored there. Google might transfer the personal
        information collected via this technical procedure to third parties.
      </p>

      <p>
        As IP anonymization is activated on our website, your IP address will be
        shortened by Google within Member States of the European Union or other
        states in agreement with the European Economic Area. Only in exceptional
        cases, the full IP address is sent to and shortened by a Google server
        in the USA. On behalf of the operator of the website, Google will use
        this information to evaluate your use of the website, compile reports on
        website activity and to provide further services related to website and
        internet use to us. The IP address transferred through your browser to
        Google Analytics will not be combined with other data held by Google.
      </p>

      <p>
        You can prevent the storage of cookies by a corresponding setting of
        your browser software.
      </p>

      <p>
        In addition, you may prevent the collection of the data generated by the
        cookie and related to your use of the website (including your IP
        address) by Google as well as the processing of this data by Google by
        downloading and installing the browser plug-in available under the
        following link:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>
      </p>

      <p>
        This browser add-on informs Google Analytics via JavaScript that no data
        and information about website visits may be transmitted to Google
        Analytics.
      </p>

      <p>
        In addition, a cookie already set by Google Analytics can be deleted at
        any time via the Internet browser or other software programs.
      </p>

      <p>
        Further information and Google's applicable privacy regulations can be
        found at{' '}
        <a href="https://policies.google.com/privacy?hl=en">
          https://policies.google.com/privacy?hl=en
        </a>
        and{' '}
        <a href="https://marketingplatform.google.com/about/">
          https://marketingplatform.google.com/about/
        </a>{' '}
        The following link provides a further explanation of Google Analytics
        <a href="https://marketingplatform.google.com/about/">
          https://marketingplatform.google.com/about/
        </a>
        .
      </p>
    </Container>
  </Layout>
);

export default PrivacyPolicy;

// PrivacyPolicy.propTypes = {
//   center: PropTypes.object,
// };
